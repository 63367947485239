import { Container, ContainerSucces } from './styles'
import { useForm, ValidationError } from '@formspree/react'
import { toast, ToastContainer } from 'react-toastify'
import "./forms.css";
import { useEffect, useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
export function Form() {
  let formm = useRef();
  const [state, handleSubmit] = useForm('xknkpqry')
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lxpl0zw', 'template_8uhu4e8', formm.current, 'jhiyefj-OANel5_Q8')
      .then((result) => {
          console.log(result.text);
          alert(' Message Sent Succesfully');
      }, (error) => {
          alert(' Message Not Sent : Error 404');
        
      });
  };
  return (
    <Container>
    
      <h2>Let's Work Together</h2>
      <form   ref={formm}  onSubmit={sendEmail}>
        <input
          placeholder="Name"
          id="name"
          type="text"
          name="from_name"
        
          required
        />
        <input
          placeholder="Email"
          id="email"
          type="email"
          name="email_id"
          
          required
        />
     
        <textarea
          required
          placeholder="Leave your message"
          id="message"
          name="message"
        
        />
      
        <button
          type="submit"
         
        >

          Submit
        </button>
      </form>
      <ToastContainer />
    </Container>
  )
}
