import { Container } from "./styles";
import TataCliq from "../../assets/tata_cliq.png"
import Splipay from "../../assets/splipay_3.png"
import Recipe from "../../assets/recipe.png"
import ScrollAnimation from "react-animate-on-scroll";
import {AiFillGithub,AiOutlineLaptop} from "react-icons/ai"
import GitHubCalendar from 'react-github-calendar';
export function Portfolio() {
  return (
    <Container id="portfolio">
      <h2>My Portfolio</h2>
      <div className="projects">

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <div className="body">

              <h3 style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}}>  <a target="_blank" href="https://github.com/CartikKG/TataCliq_Full-Stack"> <AiFillGithub fontSize="40px" color="white"/> </a>TataCliq Replica<a  href="https://tata-cliq.netlify.app/"target="_blank"> <AiOutlineLaptop fontSize="40px" color="white"/> </a> </h3>
              <img width="100%" src={TataCliq}/>
              <p style={ { marginBottom:"4px", marginTop:"3px"}}>Colaborative Project</p  > 
              <p>
              React project for styling we use ChakraUI and for backend we use MongoDB and for Authentication we use JWT token
              </p>
            </div>
            <footer>
               <ul className="tech-list" style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}} >
                <img style={{width:"38px"}} src="https://img.icons8.com/office/512/react.png"/>            
                <img style={{width:"38px"}} src="https://img.icons8.com/color/512/chakra-ui.png"/>            
                <img style={{width:"38px", borderRadius:"50%"}} src="https://cdn.imgbin.com/1/18/9/imgbin-node-js-javascript-database-mongodb-native-MznsPpw43gVjLGaCqfjtzBLP8.jpg"/>            
                <img style={{width:"38px", borderRadius:"50%"}} src="https://www.tutorialsteacher.com/Content/images/home/mongodb.svg"/>            
                <img style={{width:"38px",border:"3px solid grey", padding:"3px", borderRadius:"50%",filter:"invert(100%)"}} src="https://img.icons8.com/ios/512/express-js.png"/>            
               </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <div className="body">

              <h3 style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}}>  <a target="_blank" href="https://github.com/CartikKG/SplitPay"> <AiFillGithub fontSize="40px" color="white"/> </a>SplitPay<a  href="https://splitpays.netlify.app"target="_blank"> <AiOutlineLaptop fontSize="40px" color="white"/> </a> </h3>
              <img width="100%" src={Splipay}/>
              <p style={ { marginBottom:"4px", marginTop:"3px"}}>Individual Project</p  >
              <p>
              Split pay is a multiuser application. Here you can manage your personal expenses as well as group expenses with multiple people, and they can also add their expenses too 
              </p>
            </div>
            <footer>
               <ul className="tech-list" style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}} >
                <img style={{width:"38px"}} src="https://img.icons8.com/office/512/react.png"/>            
                <img style={{width:"38px"}} src="https://img.icons8.com/color/512/chakra-ui.png"/>            
                <img style={{width:"38px", borderRadius:"50%"}} src="https://cdn.imgbin.com/1/18/9/imgbin-node-js-javascript-database-mongodb-native-MznsPpw43gVjLGaCqfjtzBLP8.jpg"/>            
                <img style={{width:"38px", borderRadius:"50%"}} src="https://www.tutorialsteacher.com/Content/images/home/mongodb.svg"/>            
                <img style={{width:"38px",border:"3px solid grey", padding:"3px", borderRadius:"50%",filter:"invert(100%)"}} src="https://img.icons8.com/ios/512/express-js.png"/>                     
               </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            
            <div className="body">
             
              <h3 style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}}>  <a target="_blank" href="https://github.com/CartikKG/Shopdress"> <AiFillGithub fontSize="40px" color="white"/> </a>ShopDressup<a  href="https://shopdressup.netlify.app/"target="_blank"> <AiOutlineLaptop fontSize="40px" color="white"/> </a> </h3>
              <img width="100%"  src="https://i.imgur.com/doOBVK6.png"/>
              <p style={ { marginBottom:"4px", marginTop:"3px"}}>Colaborative Project</p  >
              <p>
              A E-commerce website user able to login with the google authentication also and here they can see some Good UI and same also is Fully Responsive . This is build under the React framework and for styling we use Chakra UI and for data we use JSON SERVER
              </p>
            </div>
            <footer>
               <ul className="tech-list" style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}} >
                <img style={{width:"46px"}} src="https://img.icons8.com/office/512/react.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/chakra-ui.png"/>            
               </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
           
            <div className="body">
              <h3 style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}}>  <a  href="https://github.com/CartikKG/Anthropologie_Project"target="_blank"> <AiFillGithub fontSize="40px" color="white"/> </a>Anthropologie<a  href="https://anthropologie-clonee.netlify.app/"target="_blank"> <AiOutlineLaptop fontSize="40px" color="white"/> </a> </h3>
              <img width="100%" src="https://i.imgur.com/PNXa36A.png"/>
              <p style={ { marginBottom:"4px", marginTop:"3px"}}>Colaborative Project</p  >
              <p>
              Anthropologie is an Women Clothing Site we can se here , Login Signup Functionality , and some cool Functionality like every product brief description and also see some cool Functionality this site aslo fully responsive
              </p>
            </div>
            <footer>
               <ul className="tech-list" style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}} >
                <img style={{width:"46px"}} src="https://img.icons8.com/fluency/512/css3.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/html-5--v1.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/javascript.png"/>        
               </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
         
            <div className="body">
              <h3 style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}}>  <a  href="https://github.com/CartikKG/Blinkit"target="_blank"> <AiFillGithub fontSize="40px" color="white"/> </a>Blink-it<a  href="https://blinkit-clone-masai.netlify.app/"target="_blank"> <AiOutlineLaptop fontSize="40px" color="white"/> </a> </h3>
              <img  width="100%" src="https://i.imgur.com/yvaJjT0.png"/>
              <p style={ { marginBottom:"4px", marginTop:"3px"}}>Colaborative Project</p  >
              <p>
              Blinkit, In this Project user can able to Create an account and buy products and Placeorders and also use some fancy functionality
             </p>
            </div>
            <footer>
               <ul className="tech-list" style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}} >
                <img style={{width:"46px"}} src="https://img.icons8.com/fluency/512/css3.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/html-5--v1.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/javascript.png"/>        
               </ul>
            </footer>
          </div>
        </ScrollAnimation>


        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <div className="body">

              <h3 style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}}>  <a target="_blank" href="https://github.com/CartikKG/Mrs-.-RECIPE-S"> <AiFillGithub fontSize="40px" color="white"/> </a>Mrs-.-RECIPE-S<a  href="https://mrs-recipes.netlify.app/"target="_blank"> <AiOutlineLaptop fontSize="40px" color="white"/> </a> </h3>
              <img width="100%" src={Recipe}/>
              <p style={ { marginBottom:"4px", marginTop:"3px"}}>Individual Project</p  >
              <p>
              Purely Made with html,css and javascript, Signup-Login functionality, The main feature is that here you can search for any recipe and you get all the details with a dedicated video
              </p>
            </div>
            <footer>
               <ul className="tech-list" style={{display:'flex',margin:"auto", justifyContent:"space-around", alignItems:"center"}} >
                <img style={{width:"46px"}} src="https://img.icons8.com/fluency/512/css3.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/html-5--v1.png"/>            
                <img style={{width:"46px"}} src="https://img.icons8.com/color/512/javascript.png"/>        
               </ul>
            </footer>
          </div>
        </ScrollAnimation>
       
        
        {/* <ScrollAnimation animateIn="flipInX"> */}
          {/* <div className="project"> */}
            {/* <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://drrohitdamor.com" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header> */}
            {/* <div className="body">
              <h3 text-aligh="center">Masai Dashboard HTML-CSS-JS</h3>
              <img width="100%" src="https://i.imgur.com/IkYDQQj.png"/>
              <p>
              An Masai Student Dashboard, Here we can view all details of the past and upcoming lectures and Assignment Here I build everything that you can enjoy the admin panel as well so you can add or delete lecture and assignment as well
                {/* A Website made to Book an Online Appointment. Peoples can Schedule an Appointment with you in under a Minute. This type of site is helpful to get online appointment bookings. */}
              {/* </p> */}
            {/* </div> */} 
            {/* <footer> */}
              {/* <ul className="tech-list">
                <li>Wordpress</li>
              </ul>
            </footer>width: 40%;
    height: 200px;
          </div> */}
          
      </div>
      <ScrollAnimation border="2px solid red" margin="auto" animateIn="flipInX">
        <h1 style={{fontSize:"39px", textAlign:"center",borderRadius:"10px",paddingTop:"13px", paddingBottom:"13px"}}> My GitHub Calendar </h1>
        <div style={{border:"5px solid #199de1",borderStartStartRadius:"120px",borderBottomRightRadius:"110px", margin:"auto", display:"flex", justifyContent:"center",alignItems:"center", borderRadius:"19px", flexDirection:"column",padding:"17px"}}> 
         <GitHubCalendar margin="auto" width="100%" username="cartikkg" /></div>
     </ScrollAnimation>
      {/* <ScrollAnimation margin="auto" animateIn="flipInX">
       <div id="githubdiv" > 
         <img src="https://github-readme-stats.vercel.app/api?username=CartikKG&show_icons=true&theme=dark"/>
         <img  src="https://github-readme-stats.vercel.app/api/top-langs/?username=CartikKG&layout=compact&theme=dark"/>
         </div>
     </ScrollAnimation> */}
    </Container>
  );
}