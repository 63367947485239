import styled from "styled-components";


export const Container = styled.section`
  margin-top: 15rem;
  
  h2{
    text-align: center;
    font-size: 4rem;
    margin-bottom: 5rem;
  }
  .projects{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(320px, 1fr));
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;
    overflow: hidden;

    .project{
      padding: 2rem 1.8rem;
      background-color: #2b2b2b;
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #FFF;
      &:hover{
        transform: translateY(-5px);
        background-color: #17136f;
      }

      header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 3.6rem;
        .project-links{
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        a > img {
          width: 5.0rem;
        }
      }
      
      h3{
        margin-bottom: 2rem;
      }

      p{
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        a{
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover{
            color: var(--green);
          }
        }
      }

      footer{
        margin-top: auto;
        .tech-list{
          display: flex;
          align-items: center;
          gap: 2rem;
          font-size: 1.4rem;
          opacity: 0.6;
        }
      }

    }
  }
  #githubdiv{
     
   border:5px solid #199de1;
   border-start-end-radius:110px;
   border-bottom-right-radius:90px;
   margin:auto;
   margin-top:30px;
  display:flex;
  justify-content:space-around;
  align-items:center;
  border-radius:19px;
  padding:17px
  }
  #githubdiv>img{
    width:40%;
    height:200px
  }
  @media (max-width: 960px){
    #githubdiv{
      
      flex-direction: column;
      padding:0%;
    }
    #githubdiv img{
      width:90%;
     
    }
   
  }
  
  @media (max-width: 414px){
    .projects{

      grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
    }
  }

`